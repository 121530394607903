/* button,
select {
    text-transform: none
}

select {
    word-wrap: normal
} */

/* [type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
} */

.img-fluid {
  max-width: 100%;
  height: auto
}

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
  height: auto
}

.figure {
  display: inline-block
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1
}

.figure-caption {
  font-size: 90%;
  color: #6c757d
}

.no-gutters {
  margin-right: 0;
  margin-left: 0
}

/* .btn-outline-primary {
  color: #007bff;
  border-color: #007bff
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
} */

.d-none {
  display: none !important
}

.d-inline {
  display: inline !important
}

.d-inline-block {
  display: inline-block !important
}

.d-block {
  display: block !important
}

.d-table {
  display: table !important
}

.d-table-row {
  display: table-row !important
}

.d-table-cell {
  display: table-cell !important
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important
}

@media (min-width:576px) {
  .d-sm-none {
      display: none !important
  }

  .d-sm-inline {
      display: inline !important
  }

  .d-sm-inline-block {
      display: inline-block !important
  }

  .d-sm-block {
      display: block !important
  }

  .d-sm-table {
      display: table !important
  }

  .d-sm-table-row {
      display: table-row !important
  }

  .d-sm-table-cell {
      display: table-cell !important
  }

  .d-sm-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important
  }

  .d-sm-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important
  }
}

@media (min-width:768px) {
  .d-md-none {
      display: none !important
  }

  .d-md-inline {
      display: inline !important
  }

  .d-md-inline-block {
      display: inline-block !important
  }

  .d-md-block {
      display: block !important
  }

  .d-md-table {
      display: table !important
  }

  .d-md-table-row {
      display: table-row !important
  }

  .d-md-table-cell {
      display: table-cell !important
  }

  .d-md-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important
  }

  .d-md-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important
  }
}

@media (min-width:992px) {
  .d-lg-none {
      display: none !important
  }

  .d-lg-inline {
      display: inline !important
  }

  .d-lg-inline-block {
      display: inline-block !important
  }

  .d-lg-block {
      display: block !important
  }

  .d-lg-table {
      display: table !important
  }

  .d-lg-table-row {
      display: table-row !important
  }

  .d-lg-table-cell {
      display: table-cell !important
  }

  .d-lg-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important
  }

  .d-lg-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important
  }
}

@media (min-width:1200px) {
  .d-xl-none {
      display: none !important
  }

  .d-xl-inline {
      display: inline !important
  }

  .d-xl-inline-block {
      display: inline-block !important
  }

  .d-xl-block {
      display: block !important
  }

  .d-xl-table {
      display: table !important
  }

  .d-xl-table-row {
      display: table-row !important
  }

  .d-xl-table-cell {
      display: table-cell !important
  }

  .d-xl-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important
  }

  .d-xl-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important
  }
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important
}

@media (min-width:576px) {
  .flex-sm-row {
      -ms-flex-direction: row !important;
      flex-direction: row !important
  }

  .flex-sm-column {
      -ms-flex-direction: column !important;
      flex-direction: column !important
  }

  .flex-sm-row-reverse {
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important
  }

  .flex-sm-column-reverse {
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important
  }

  .flex-sm-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important
  }

  .flex-sm-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important
  }

  .flex-sm-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important
  }

  .flex-sm-fill {
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important
  }

  .flex-sm-grow-0 {
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important
  }

  .flex-sm-grow-1 {
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important
  }

  .flex-sm-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important
  }

  .flex-sm-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important
  }

  .justify-content-sm-start {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important
  }

  .justify-content-sm-end {
      -ms-flex-pack: end !important;
      justify-content: flex-end !important
  }

  .justify-content-sm-center {
      -ms-flex-pack: center !important;
      justify-content: center !important
  }

  .justify-content-sm-between {
      -ms-flex-pack: justify !important;
      justify-content: space-between !important
  }

  .justify-content-sm-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important
  }

  .align-items-sm-start {
      -ms-flex-align: start !important;
      align-items: flex-start !important
  }

  .align-items-sm-end {
      -ms-flex-align: end !important;
      align-items: flex-end !important
  }

  .align-items-sm-center {
      -ms-flex-align: center !important;
      align-items: center !important
  }

  .align-items-sm-baseline {
      -ms-flex-align: baseline !important;
      align-items: baseline !important
  }

  .align-items-sm-stretch {
      -ms-flex-align: stretch !important;
      align-items: stretch !important
  }

  .align-content-sm-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important
  }

  .align-content-sm-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important
  }

  .align-content-sm-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important
  }

  .align-content-sm-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important
  }

  .align-content-sm-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important
  }

  .align-content-sm-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important
  }

  .align-self-sm-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important
  }

  .align-self-sm-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important
  }

  .align-self-sm-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important
  }

  .align-self-sm-center {
      -ms-flex-item-align: center !important;
      align-self: center !important
  }

  .align-self-sm-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important
  }

  .align-self-sm-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important
  }
}

@media (min-width:768px) {
  .flex-md-row {
      -ms-flex-direction: row !important;
      flex-direction: row !important
  }

  .flex-md-column {
      -ms-flex-direction: column !important;
      flex-direction: column !important
  }

  .flex-md-row-reverse {
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important
  }

  .flex-md-column-reverse {
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important
  }

  .flex-md-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important
  }

  .flex-md-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important
  }

  .flex-md-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important
  }

  .flex-md-fill {
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important
  }

  .flex-md-grow-0 {
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important
  }

  .flex-md-grow-1 {
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important
  }

  .flex-md-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important
  }

  .flex-md-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important
  }

  .justify-content-md-start {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important
  }

  .justify-content-md-end {
      -ms-flex-pack: end !important;
      justify-content: flex-end !important
  }

  .justify-content-md-center {
      -ms-flex-pack: center !important;
      justify-content: center !important
  }

  .justify-content-md-between {
      -ms-flex-pack: justify !important;
      justify-content: space-between !important
  }

  .justify-content-md-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important
  }

  .align-items-md-start {
      -ms-flex-align: start !important;
      align-items: flex-start !important
  }

  .align-items-md-end {
      -ms-flex-align: end !important;
      align-items: flex-end !important
  }

  .align-items-md-center {
      -ms-flex-align: center !important;
      align-items: center !important
  }

  .align-items-md-baseline {
      -ms-flex-align: baseline !important;
      align-items: baseline !important
  }

  .align-items-md-stretch {
      -ms-flex-align: stretch !important;
      align-items: stretch !important
  }

  .align-content-md-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important
  }

  .align-content-md-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important
  }

  .align-content-md-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important
  }

  .align-content-md-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important
  }

  .align-content-md-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important
  }

  .align-content-md-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important
  }

  .align-self-md-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important
  }

  .align-self-md-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important
  }

  .align-self-md-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important
  }

  .align-self-md-center {
      -ms-flex-item-align: center !important;
      align-self: center !important
  }

  .align-self-md-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important
  }

  .align-self-md-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important
  }
}

@media (min-width:992px) {
  .flex-lg-row {
      -ms-flex-direction: row !important;
      flex-direction: row !important
  }

  .flex-lg-column {
      -ms-flex-direction: column !important;
      flex-direction: column !important
  }

  .flex-lg-row-reverse {
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important
  }

  .flex-lg-column-reverse {
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important
  }

  .flex-lg-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important
  }

  .flex-lg-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important
  }

  .flex-lg-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important
  }

  .flex-lg-fill {
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important
  }

  .flex-lg-grow-0 {
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important
  }

  .flex-lg-grow-1 {
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important
  }

  .flex-lg-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important
  }

  .flex-lg-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important
  }

  .justify-content-lg-start {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important
  }

  .justify-content-lg-end {
      -ms-flex-pack: end !important;
      justify-content: flex-end !important
  }

  .justify-content-lg-center {
      -ms-flex-pack: center !important;
      justify-content: center !important
  }

  .justify-content-lg-between {
      -ms-flex-pack: justify !important;
      justify-content: space-between !important
  }

  .justify-content-lg-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important
  }

  .align-items-lg-start {
      -ms-flex-align: start !important;
      align-items: flex-start !important
  }

  .align-items-lg-end {
      -ms-flex-align: end !important;
      align-items: flex-end !important
  }

  .align-items-lg-center {
      -ms-flex-align: center !important;
      align-items: center !important
  }

  .align-items-lg-baseline {
      -ms-flex-align: baseline !important;
      align-items: baseline !important
  }

  .align-items-lg-stretch {
      -ms-flex-align: stretch !important;
      align-items: stretch !important
  }

  .align-content-lg-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important
  }

  .align-content-lg-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important
  }

  .align-content-lg-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important
  }

  .align-content-lg-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important
  }

  .align-content-lg-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important
  }

  .align-content-lg-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important
  }

  .align-self-lg-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important
  }

  .align-self-lg-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important
  }

  .align-self-lg-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important
  }

  .align-self-lg-center {
      -ms-flex-item-align: center !important;
      align-self: center !important
  }

  .align-self-lg-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important
  }

  .align-self-lg-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important
  }
}

@media (min-width:1200px) {
  .flex-xl-row {
      -ms-flex-direction: row !important;
      flex-direction: row !important
  }

  .flex-xl-column {
      -ms-flex-direction: column !important;
      flex-direction: column !important
  }

  .flex-xl-row-reverse {
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important
  }

  .flex-xl-column-reverse {
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important
  }

  .flex-xl-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important
  }

  .flex-xl-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important
  }

  .flex-xl-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important
  }

  .flex-xl-fill {
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important
  }

  .flex-xl-grow-0 {
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important
  }

  .flex-xl-grow-1 {
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important
  }

  .flex-xl-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important
  }

  .flex-xl-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important
  }

  .justify-content-xl-start {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important
  }

  .justify-content-xl-end {
      -ms-flex-pack: end !important;
      justify-content: flex-end !important
  }

  .justify-content-xl-center {
      -ms-flex-pack: center !important;
      justify-content: center !important
  }

  .justify-content-xl-between {
      -ms-flex-pack: justify !important;
      justify-content: space-between !important
  }

  .justify-content-xl-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important
  }

  .align-items-xl-start {
      -ms-flex-align: start !important;
      align-items: flex-start !important
  }

  .align-items-xl-end {
      -ms-flex-align: end !important;
      align-items: flex-end !important
  }

  .align-items-xl-center {
      -ms-flex-align: center !important;
      align-items: center !important
  }

  .align-items-xl-baseline {
      -ms-flex-align: baseline !important;
      align-items: baseline !important
  }

  .align-items-xl-stretch {
      -ms-flex-align: stretch !important;
      align-items: stretch !important
  }

  .align-content-xl-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important
  }

  .align-content-xl-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important
  }

  .align-content-xl-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important
  }

  .align-content-xl-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important
  }

  .align-content-xl-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important
  }

  .align-content-xl-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important
  }

  .align-self-xl-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important
  }

  .align-self-xl-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important
  }

  .align-self-xl-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important
  }

  .align-self-xl-center {
      -ms-flex-item-align: center !important;
      align-self: center !important
  }

  .align-self-xl-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important
  }

  .align-self-xl-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important
  }
}

.float-left {
  float: left !important
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important
}

@media (min-width:576px) {
  .float-sm-left {
      float: left !important
  }

  .float-sm-right {
      float: right !important
  }

  .float-sm-none {
      float: none !important
  }
}

@media (min-width:768px) {
  .float-md-left {
      float: left !important
  }

  .float-md-right {
      float: right !important
  }

  .float-md-none {
      float: none !important
  }
}

@media (min-width:992px) {
  .float-lg-left {
      float: left !important
  }

  .float-lg-right {
      float: right !important
  }

  .float-lg-none {
      float: none !important
  }
}

@media (min-width:1200px) {
  .float-xl-left {
      float: left !important
  }

  .float-xl-right {
      float: right !important
  }

  .float-xl-none {
      float: none !important
  }
}

.overflow-auto {
  overflow: auto !important
}

.overflow-hidden {
  overflow: hidden !important
}

.position-static {
  position: static !important
}

.position-relative {
  position: relative !important
}

.position-absolute {
  position: absolute !important
}

.position-fixed {
  position: fixed !important
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
  .sticky-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
  box-shadow: none !important
}

.w-25 {
  width: 25% !important
}

.w-50 {
  width: 50% !important
}

.w-75 {
  width: 75% !important
}

.w-100 {
  width: 100% !important
}

.w-auto {
  width: auto !important
}

.h-25 {
  height: 25% !important
}

.h-50 {
  height: 50% !important
}

.h-75 {
  height: 75% !important
}

.h-100 {
  height: 100% !important
}

.h-auto {
  height: auto !important
}

.mw-100 {
  max-width: 100% !important
}

.mh-100 {
  max-height: 100% !important
}

.min-vw-100 {
  min-width: 100vw !important
}

.min-vh-100 {
  min-height: 100vh !important
}

.vw-100 {
  width: 100vw !important
}

.vh-100 {
  height: 100vh !important
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0)
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-1_5 {
  margin: 0.5rem !important;
}

.mt-1_5,
.my-1_5 {
  margin-top: 0.5rem !important;
}

.mr-1_5,
.mx-1_5 {
  margin-right: 0.5rem !important;
}

.mb-1_5,
.my-1_5 {
  margin-bottom: 0.5rem !important;
}

.ml-1_5,
.mx-1_5 {
  margin-left: 0.5rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-2_5 {
  margin: 0.8rem !important;
}

.mt-2_5,
.my-2_5 {
  margin-top: 0.8rem !important;
}

.mr-2_5,
.mx-2_5 {
  margin-right: 0.8rem !important;
}

.mb-2_5,
.my-2_5 {
  margin-bottom: 0.8rem !important;
}

.ml-2_5,
.mx-2_5 {
  margin-left: 0.8rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-4_5 {
  margin: 2rem !important;
}

.mt-4_5,
.my-4_5 {
  margin-top: 2rem !important;
}

.mr-4_5,
.mx-4_5 {
  margin-right: 2rem !important;
}

.mb-4_5,
.my-4_5 {
  margin-bottom: 2rem !important;
}

.ml-4_5,
.mx-4_5 {
  margin-left: 2rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-5_5 {
  margin: 3.5rem !important;
}

.mt-5_5,
.my-5_5 {
  margin-top: 3.5rem !important;
}

.mr-5_5,
.mx-5_5 {
  margin-right: 3.5rem !important;
}

.mb-5_5,
.my-5_5 {
  margin-bottom: 3.5rem !important;
}

.ml-5_5,
.mx-5_5 {
  margin-left: 3.5rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.m-6_5 {
  margin: 4.5rem !important;
}

.mt-6_5,
.my-6_5 {
  margin-top: 4.5rem !important;
}

.mr-6_5,
.mx-6_5 {
  margin-right: 4.5rem !important;
}

.mb-6_5,
.my-6_5 {
  margin-bottom: 4.5rem !important;
}

.ml-6_5,
.mx-6_5 {
  margin-left: 4.5rem !important;
}

.m-7 {
  margin: 5rem !important;
}

.mt-7,
.my-7 {
  margin-top: 5rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 5rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 5rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 5rem !important;
}

.m-7_5 {
  margin: 5.5rem !important;
}

.mt-7_5,
.my-7_5 {
  margin-top: 5.5rem !important;
}

.mr-7_5,
.mx-7_5 {
  margin-right: 5.5rem !important;
}

.mb-7_5,
.my-7_5 {
  margin-bottom: 5.5rem !important;
}

.ml-7_5,
.mx-7_5 {
  margin-left: 5.5rem !important;
}

.m-8 {
  margin: 6rem !important;
}

.mt-8,
.my-8 {
  margin-top: 6rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 6rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 6rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 6rem !important;
}

.m-8_5 {
  margin: 6.5rem !important;
}

.mt-8_5,
.my-8_5 {
  margin-top: 6.5rem !important;
}

.mr-8_5,
.mx-8_5 {
  margin-right: 6.5rem !important;
}

.mb-8_5,
.my-8_5 {
  margin-bottom: 6.5rem !important;
}

.ml-8_5,
.mx-8_5 {
  margin-left: 6.5rem !important;
}

.m-9 {
  margin: 7rem !important;
}

.mt-9,
.my-9 {
  margin-top: 7rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 7rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 7rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 7rem !important;
}

.m-9_5 {
  margin: 7.5rem !important;
}

.mt-9_5,
.my-9_5 {
  margin-top: 7.5rem !important;
}

.mr-9_5,
.mx-9_5 {
  margin-right: 7.5rem !important;
}

.mb-9_5,
.my-9_5 {
  margin-bottom: 7.5rem !important;
}

.ml-9_5,
.mx-9_5 {
  margin-left: 7.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-1_5 {
  padding: 0.5rem !important;
}

.pt-1_5,
.py-1_5 {
  padding-top: 0.5rem !important;
}

.pr-1_5,
.px-1_5 {
  padding-right: 0.5rem !important;
}

.pb-1_5,
.py-1_5 {
  padding-bottom: 0.5rem !important;
}

.pl-1_5,
.px-1_5 {
  padding-left: 0.5rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-2_5 {
  padding: 0.8rem !important;
}

.pt-2_5,
.py-2_5 {
  padding-top: 0.8rem !important;
}

.pr-2_5,
.px-2_5 {
  padding-right: 0.8rem !important;
}

.pb-2_5,
.py-2_5 {
  padding-bottom: 0.8rem !important;
}

.pl-2_5,
.px-2_5 {
  padding-left: 0.8rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-4_5 {
  padding: 2rem !important;
}

.pt-4_5,
.py-4_5 {
  padding-top: 2rem !important;
}

.pr-4_5,
.px-4_5 {
  padding-right: 2rem !important;
}

.pb-4_5,
.py-4_5 {
  padding-bottom: 2rem !important;
}

.pl-4_5,
.px-4_5 {
  padding-left: 2rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-5_5 {
  padding: 3.5rem !important;
}

.pt-5_5,
.py-5_5 {
  padding-top: 3.5rem !important;
}

.pr-5_5,
.px-5_5 {
  padding-right: 3.5rem !important;
}

.pb-5_5,
.py-5_5 {
  padding-bottom: 3.5rem !important;
}

.pl-5_5,
.px-5_5 {
  padding-left: 3.5rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.p-6_5 {
  padding: 4.5rem !important;
}

.pt-6_5,
.py-6_5 {
  padding-top: 4.5rem !important;
}

.pr-6_5,
.px-6_5 {
  padding-right: 4.5rem !important;
}

.pb-6_5,
.py-6_5 {
  padding-bottom: 4.5rem !important;
}

.pl-6_5,
.px-6_5 {
  padding-left: 4.5rem !important;
}

.p-7 {
  padding: 5rem !important;
}

.pt-7,
.py-7 {
  padding-top: 5rem !important;
}

.pr-7,
.px-7 {
  padding-right: 5rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 5rem !important;
}

.pl-7,
.px-7 {
  padding-left: 5rem !important;
}

.p-7_5 {
  padding: 5.5rem !important;
}

.pt-7_5,
.py-7_5 {
  padding-top: 5.5rem !important;
}

.pr-7_5,
.px-7_5 {
  padding-right: 5.5rem !important;
}

.pb-7_5,
.py-7_5 {
  padding-bottom: 5.5rem !important;
}

.pl-7_5,
.px-7_5 {
  padding-left: 5.5rem !important;
}

.p-8 {
  padding: 6rem !important;
}

.pt-8,
.py-8 {
  padding-top: 6rem !important;
}

.pr-8,
.px-8 {
  padding-right: 6rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 6rem !important;
}

.pl-8,
.px-8 {
  padding-left: 6rem !important;
}

.p-8_5 {
  padding: 6.5rem !important;
}

.pt-8_5,
.py-8_5 {
  padding-top: 6.5rem !important;
}

.pr-8_5,
.px-8_5 {
  padding-right: 6.5rem !important;
}

.pb-8_5,
.py-8_5 {
  padding-bottom: 6.5rem !important;
}

.pl-8_5,
.px-8_5 {
  padding-left: 6.5rem !important;
}

.p-9 {
  padding: 7rem !important;
}

.pt-9,
.py-9 {
  padding-top: 7rem !important;
}

.pr-9,
.px-9 {
  padding-right: 7rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 7rem !important;
}

.pl-9,
.px-9 {
  padding-left: 7rem !important;
}

.p-9_5 {
  padding: 7.5rem !important;
}

.pt-9_5,
.py-9_5 {
  padding-top: 7.5rem !important;
}

.pr-9_5,
.px-9_5 {
  padding-right: 7.5rem !important;
}

.pb-9_5,
.py-9_5 {
  padding-bottom: 7.5rem !important;
}

.pl-9_5,
.px-9_5 {
  padding-left: 7.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-1_5 {
    margin: 0.5rem !important;
  }
  .mt-sm-1_5,
  .my-sm-1_5 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-1_5,
  .mx-sm-1_5 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-1_5,
  .my-sm-1_5 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-1_5,
  .mx-sm-1_5 {
    margin-left: 0.5rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-2_5 {
    margin: 0.8rem !important;
  }
  .mt-sm-2_5,
  .my-sm-2_5 {
    margin-top: 0.8rem !important;
  }
  .mr-sm-2_5,
  .mx-sm-2_5 {
    margin-right: 0.8rem !important;
  }
  .mb-sm-2_5,
  .my-sm-2_5 {
    margin-bottom: 0.8rem !important;
  }
  .ml-sm-2_5,
  .mx-sm-2_5 {
    margin-left: 0.8rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-4_5 {
    margin: 2rem !important;
  }
  .mt-sm-4_5,
  .my-sm-4_5 {
    margin-top: 2rem !important;
  }
  .mr-sm-4_5,
  .mx-sm-4_5 {
    margin-right: 2rem !important;
  }
  .mb-sm-4_5,
  .my-sm-4_5 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-4_5,
  .mx-sm-4_5 {
    margin-left: 2rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-5_5 {
    margin: 3.5rem !important;
  }
  .mt-sm-5_5,
  .my-sm-5_5 {
    margin-top: 3.5rem !important;
  }
  .mr-sm-5_5,
  .mx-sm-5_5 {
    margin-right: 3.5rem !important;
  }
  .mb-sm-5_5,
  .my-sm-5_5 {
    margin-bottom: 3.5rem !important;
  }
  .ml-sm-5_5,
  .mx-sm-5_5 {
    margin-left: 3.5rem !important;
  }
  .m-sm-6 {
    margin: 4rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4rem !important;
  }
  .m-sm-6_5 {
    margin: 4.5rem !important;
  }
  .mt-sm-6_5,
  .my-sm-6_5 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-6_5,
  .mx-sm-6_5 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-6_5,
  .my-sm-6_5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-6_5,
  .mx-sm-6_5 {
    margin-left: 4.5rem !important;
  }
  .m-sm-7 {
    margin: 5rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 5rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 5rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 5rem !important;
  }
  .m-sm-7_5 {
    margin: 5.5rem !important;
  }
  .mt-sm-7_5,
  .my-sm-7_5 {
    margin-top: 5.5rem !important;
  }
  .mr-sm-7_5,
  .mx-sm-7_5 {
    margin-right: 5.5rem !important;
  }
  .mb-sm-7_5,
  .my-sm-7_5 {
    margin-bottom: 5.5rem !important;
  }
  .ml-sm-7_5,
  .mx-sm-7_5 {
    margin-left: 5.5rem !important;
  }
  .m-sm-8 {
    margin: 6rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 6rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 6rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 6rem !important;
  }
  .m-sm-8_5 {
    margin: 6.5rem !important;
  }
  .mt-sm-8_5,
  .my-sm-8_5 {
    margin-top: 6.5rem !important;
  }
  .mr-sm-8_5,
  .mx-sm-8_5 {
    margin-right: 6.5rem !important;
  }
  .mb-sm-8_5,
  .my-sm-8_5 {
    margin-bottom: 6.5rem !important;
  }
  .ml-sm-8_5,
  .mx-sm-8_5 {
    margin-left: 6.5rem !important;
  }
  .m-sm-9 {
    margin: 7rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 7rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 7rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 7rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 7rem !important;
  }
  .m-sm-9_5 {
    margin: 7.5rem !important;
  }
  .mt-sm-9_5,
  .my-sm-9_5 {
    margin-top: 7.5rem !important;
  }
  .mr-sm-9_5,
  .mx-sm-9_5 {
    margin-right: 7.5rem !important;
  }
  .mb-sm-9_5,
  .my-sm-9_5 {
    margin-bottom: 7.5rem !important;
  }
  .ml-sm-9_5,
  .mx-sm-9_5 {
    margin-left: 7.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-1_5 {
    padding: 0.5rem !important;
  }
  .pt-sm-1_5,
  .py-sm-1_5 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-1_5,
  .px-sm-1_5 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-1_5,
  .py-sm-1_5 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-1_5,
  .px-sm-1_5 {
    padding-left: 0.5rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-2_5 {
    padding: 0.8rem !important;
  }
  .pt-sm-2_5,
  .py-sm-2_5 {
    padding-top: 0.8rem !important;
  }
  .pr-sm-2_5,
  .px-sm-2_5 {
    padding-right: 0.8rem !important;
  }
  .pb-sm-2_5,
  .py-sm-2_5 {
    padding-bottom: 0.8rem !important;
  }
  .pl-sm-2_5,
  .px-sm-2_5 {
    padding-left: 0.8rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-4_5 {
    padding: 2rem !important;
  }
  .pt-sm-4_5,
  .py-sm-4_5 {
    padding-top: 2rem !important;
  }
  .pr-sm-4_5,
  .px-sm-4_5 {
    padding-right: 2rem !important;
  }
  .pb-sm-4_5,
  .py-sm-4_5 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-4_5,
  .px-sm-4_5 {
    padding-left: 2rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-5_5 {
    padding: 3.5rem !important;
  }
  .pt-sm-5_5,
  .py-sm-5_5 {
    padding-top: 3.5rem !important;
  }
  .pr-sm-5_5,
  .px-sm-5_5 {
    padding-right: 3.5rem !important;
  }
  .pb-sm-5_5,
  .py-sm-5_5 {
    padding-bottom: 3.5rem !important;
  }
  .pl-sm-5_5,
  .px-sm-5_5 {
    padding-left: 3.5rem !important;
  }
  .p-sm-6 {
    padding: 4rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4rem !important;
  }
  .p-sm-6_5 {
    padding: 4.5rem !important;
  }
  .pt-sm-6_5,
  .py-sm-6_5 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-6_5,
  .px-sm-6_5 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-6_5,
  .py-sm-6_5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-6_5,
  .px-sm-6_5 {
    padding-left: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 5rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 5rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 5rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 5rem !important;
  }
  .p-sm-7_5 {
    padding: 5.5rem !important;
  }
  .pt-sm-7_5,
  .py-sm-7_5 {
    padding-top: 5.5rem !important;
  }
  .pr-sm-7_5,
  .px-sm-7_5 {
    padding-right: 5.5rem !important;
  }
  .pb-sm-7_5,
  .py-sm-7_5 {
    padding-bottom: 5.5rem !important;
  }
  .pl-sm-7_5,
  .px-sm-7_5 {
    padding-left: 5.5rem !important;
  }
  .p-sm-8 {
    padding: 6rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 6rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 6rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 6rem !important;
  }
  .p-sm-8_5 {
    padding: 6.5rem !important;
  }
  .pt-sm-8_5,
  .py-sm-8_5 {
    padding-top: 6.5rem !important;
  }
  .pr-sm-8_5,
  .px-sm-8_5 {
    padding-right: 6.5rem !important;
  }
  .pb-sm-8_5,
  .py-sm-8_5 {
    padding-bottom: 6.5rem !important;
  }
  .pl-sm-8_5,
  .px-sm-8_5 {
    padding-left: 6.5rem !important;
  }
  .p-sm-9 {
    padding: 7rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 7rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 7rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 7rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 7rem !important;
  }
  .p-sm-9_5 {
    padding: 7.5rem !important;
  }
  .pt-sm-9_5,
  .py-sm-9_5 {
    padding-top: 7.5rem !important;
  }
  .pr-sm-9_5,
  .px-sm-9_5 {
    padding-right: 7.5rem !important;
  }
  .pb-sm-9_5,
  .py-sm-9_5 {
    padding-bottom: 7.5rem !important;
  }
  .pl-sm-9_5,
  .px-sm-9_5 {
    padding-left: 7.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-1_5 {
    margin: 0.5rem !important;
  }
  .mt-md-1_5,
  .my-md-1_5 {
    margin-top: 0.5rem !important;
  }
  .mr-md-1_5,
  .mx-md-1_5 {
    margin-right: 0.5rem !important;
  }
  .mb-md-1_5,
  .my-md-1_5 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-1_5,
  .mx-md-1_5 {
    margin-left: 0.5rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-2_5 {
    margin: 0.8rem !important;
  }
  .mt-md-2_5,
  .my-md-2_5 {
    margin-top: 0.8rem !important;
  }
  .mr-md-2_5,
  .mx-md-2_5 {
    margin-right: 0.8rem !important;
  }
  .mb-md-2_5,
  .my-md-2_5 {
    margin-bottom: 0.8rem !important;
  }
  .ml-md-2_5,
  .mx-md-2_5 {
    margin-left: 0.8rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-4_5 {
    margin: 2rem !important;
  }
  .mt-md-4_5,
  .my-md-4_5 {
    margin-top: 2rem !important;
  }
  .mr-md-4_5,
  .mx-md-4_5 {
    margin-right: 2rem !important;
  }
  .mb-md-4_5,
  .my-md-4_5 {
    margin-bottom: 2rem !important;
  }
  .ml-md-4_5,
  .mx-md-4_5 {
    margin-left: 2rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-5_5 {
    margin: 3.5rem !important;
  }
  .mt-md-5_5,
  .my-md-5_5 {
    margin-top: 3.5rem !important;
  }
  .mr-md-5_5,
  .mx-md-5_5 {
    margin-right: 3.5rem !important;
  }
  .mb-md-5_5,
  .my-md-5_5 {
    margin-bottom: 3.5rem !important;
  }
  .ml-md-5_5,
  .mx-md-5_5 {
    margin-left: 3.5rem !important;
  }
  .m-md-6 {
    margin: 4rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4rem !important;
  }
  .m-md-6_5 {
    margin: 4.5rem !important;
  }
  .mt-md-6_5,
  .my-md-6_5 {
    margin-top: 4.5rem !important;
  }
  .mr-md-6_5,
  .mx-md-6_5 {
    margin-right: 4.5rem !important;
  }
  .mb-md-6_5,
  .my-md-6_5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-6_5,
  .mx-md-6_5 {
    margin-left: 4.5rem !important;
  }
  .m-md-7 {
    margin: 5rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 5rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 5rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 5rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 5rem !important;
  }
  .m-md-7_5 {
    margin: 5.5rem !important;
  }
  .mt-md-7_5,
  .my-md-7_5 {
    margin-top: 5.5rem !important;
  }
  .mr-md-7_5,
  .mx-md-7_5 {
    margin-right: 5.5rem !important;
  }
  .mb-md-7_5,
  .my-md-7_5 {
    margin-bottom: 5.5rem !important;
  }
  .ml-md-7_5,
  .mx-md-7_5 {
    margin-left: 5.5rem !important;
  }
  .m-md-8 {
    margin: 6rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 6rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 6rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 6rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 6rem !important;
  }
  .m-md-8_5 {
    margin: 6.5rem !important;
  }
  .mt-md-8_5,
  .my-md-8_5 {
    margin-top: 6.5rem !important;
  }
  .mr-md-8_5,
  .mx-md-8_5 {
    margin-right: 6.5rem !important;
  }
  .mb-md-8_5,
  .my-md-8_5 {
    margin-bottom: 6.5rem !important;
  }
  .ml-md-8_5,
  .mx-md-8_5 {
    margin-left: 6.5rem !important;
  }
  .m-md-9 {
    margin: 7rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 7rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 7rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 7rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 7rem !important;
  }
  .m-md-9_5 {
    margin: 7.5rem !important;
  }
  .mt-md-9_5,
  .my-md-9_5 {
    margin-top: 7.5rem !important;
  }
  .mr-md-9_5,
  .mx-md-9_5 {
    margin-right: 7.5rem !important;
  }
  .mb-md-9_5,
  .my-md-9_5 {
    margin-bottom: 7.5rem !important;
  }
  .ml-md-9_5,
  .mx-md-9_5 {
    margin-left: 7.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-1_5 {
    padding: 0.5rem !important;
  }
  .pt-md-1_5,
  .py-md-1_5 {
    padding-top: 0.5rem !important;
  }
  .pr-md-1_5,
  .px-md-1_5 {
    padding-right: 0.5rem !important;
  }
  .pb-md-1_5,
  .py-md-1_5 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-1_5,
  .px-md-1_5 {
    padding-left: 0.5rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-2_5 {
    padding: 0.8rem !important;
  }
  .pt-md-2_5,
  .py-md-2_5 {
    padding-top: 0.8rem !important;
  }
  .pr-md-2_5,
  .px-md-2_5 {
    padding-right: 0.8rem !important;
  }
  .pb-md-2_5,
  .py-md-2_5 {
    padding-bottom: 0.8rem !important;
  }
  .pl-md-2_5,
  .px-md-2_5 {
    padding-left: 0.8rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-4_5 {
    padding: 2rem !important;
  }
  .pt-md-4_5,
  .py-md-4_5 {
    padding-top: 2rem !important;
  }
  .pr-md-4_5,
  .px-md-4_5 {
    padding-right: 2rem !important;
  }
  .pb-md-4_5,
  .py-md-4_5 {
    padding-bottom: 2rem !important;
  }
  .pl-md-4_5,
  .px-md-4_5 {
    padding-left: 2rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-5_5 {
    padding: 3.5rem !important;
  }
  .pt-md-5_5,
  .py-md-5_5 {
    padding-top: 3.5rem !important;
  }
  .pr-md-5_5,
  .px-md-5_5 {
    padding-right: 3.5rem !important;
  }
  .pb-md-5_5,
  .py-md-5_5 {
    padding-bottom: 3.5rem !important;
  }
  .pl-md-5_5,
  .px-md-5_5 {
    padding-left: 3.5rem !important;
  }
  .p-md-6 {
    padding: 4rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4rem !important;
  }
  .p-md-6_5 {
    padding: 4.5rem !important;
  }
  .pt-md-6_5,
  .py-md-6_5 {
    padding-top: 4.5rem !important;
  }
  .pr-md-6_5,
  .px-md-6_5 {
    padding-right: 4.5rem !important;
  }
  .pb-md-6_5,
  .py-md-6_5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-6_5,
  .px-md-6_5 {
    padding-left: 4.5rem !important;
  }
  .p-md-7 {
    padding: 5rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 5rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 5rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 5rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 5rem !important;
  }
  .p-md-7_5 {
    padding: 5.5rem !important;
  }
  .pt-md-7_5,
  .py-md-7_5 {
    padding-top: 5.5rem !important;
  }
  .pr-md-7_5,
  .px-md-7_5 {
    padding-right: 5.5rem !important;
  }
  .pb-md-7_5,
  .py-md-7_5 {
    padding-bottom: 5.5rem !important;
  }
  .pl-md-7_5,
  .px-md-7_5 {
    padding-left: 5.5rem !important;
  }
  .p-md-8 {
    padding: 6rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 6rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 6rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 6rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 6rem !important;
  }
  .p-md-8_5 {
    padding: 6.5rem !important;
  }
  .pt-md-8_5,
  .py-md-8_5 {
    padding-top: 6.5rem !important;
  }
  .pr-md-8_5,
  .px-md-8_5 {
    padding-right: 6.5rem !important;
  }
  .pb-md-8_5,
  .py-md-8_5 {
    padding-bottom: 6.5rem !important;
  }
  .pl-md-8_5,
  .px-md-8_5 {
    padding-left: 6.5rem !important;
  }
  .p-md-9 {
    padding: 7rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 7rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 7rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 7rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 7rem !important;
  }
  .p-md-9_5 {
    padding: 7.5rem !important;
  }
  .pt-md-9_5,
  .py-md-9_5 {
    padding-top: 7.5rem !important;
  }
  .pr-md-9_5,
  .px-md-9_5 {
    padding-right: 7.5rem !important;
  }
  .pb-md-9_5,
  .py-md-9_5 {
    padding-bottom: 7.5rem !important;
  }
  .pl-md-9_5,
  .px-md-9_5 {
    padding-left: 7.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-1_5 {
    margin: 0.5rem !important;
  }
  .mt-lg-1_5,
  .my-lg-1_5 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-1_5,
  .mx-lg-1_5 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-1_5,
  .my-lg-1_5 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-1_5,
  .mx-lg-1_5 {
    margin-left: 0.5rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-2_5 {
    margin: 0.8rem !important;
  }
  .mt-lg-2_5,
  .my-lg-2_5 {
    margin-top: 0.8rem !important;
  }
  .mr-lg-2_5,
  .mx-lg-2_5 {
    margin-right: 0.8rem !important;
  }
  .mb-lg-2_5,
  .my-lg-2_5 {
    margin-bottom: 0.8rem !important;
  }
  .ml-lg-2_5,
  .mx-lg-2_5 {
    margin-left: 0.8rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-4_5 {
    margin: 2rem !important;
  }
  .mt-lg-4_5,
  .my-lg-4_5 {
    margin-top: 2rem !important;
  }
  .mr-lg-4_5,
  .mx-lg-4_5 {
    margin-right: 2rem !important;
  }
  .mb-lg-4_5,
  .my-lg-4_5 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-4_5,
  .mx-lg-4_5 {
    margin-left: 2rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-5_5 {
    margin: 3.5rem !important;
  }
  .mt-lg-5_5,
  .my-lg-5_5 {
    margin-top: 3.5rem !important;
  }
  .mr-lg-5_5,
  .mx-lg-5_5 {
    margin-right: 3.5rem !important;
  }
  .mb-lg-5_5,
  .my-lg-5_5 {
    margin-bottom: 3.5rem !important;
  }
  .ml-lg-5_5,
  .mx-lg-5_5 {
    margin-left: 3.5rem !important;
  }
  .m-lg-6 {
    margin: 4rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4rem !important;
  }
  .m-lg-6_5 {
    margin: 4.5rem !important;
  }
  .mt-lg-6_5,
  .my-lg-6_5 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-6_5,
  .mx-lg-6_5 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-6_5,
  .my-lg-6_5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-6_5,
  .mx-lg-6_5 {
    margin-left: 4.5rem !important;
  }
  .m-lg-7 {
    margin: 5rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 5rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 5rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 5rem !important;
  }
  .m-lg-7_5 {
    margin: 5.5rem !important;
  }
  .mt-lg-7_5,
  .my-lg-7_5 {
    margin-top: 5.5rem !important;
  }
  .mr-lg-7_5,
  .mx-lg-7_5 {
    margin-right: 5.5rem !important;
  }
  .mb-lg-7_5,
  .my-lg-7_5 {
    margin-bottom: 5.5rem !important;
  }
  .ml-lg-7_5,
  .mx-lg-7_5 {
    margin-left: 5.5rem !important;
  }
  .m-lg-8 {
    margin: 6rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 6rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 6rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 6rem !important;
  }
  .m-lg-8_5 {
    margin: 6.5rem !important;
  }
  .mt-lg-8_5,
  .my-lg-8_5 {
    margin-top: 6.5rem !important;
  }
  .mr-lg-8_5,
  .mx-lg-8_5 {
    margin-right: 6.5rem !important;
  }
  .mb-lg-8_5,
  .my-lg-8_5 {
    margin-bottom: 6.5rem !important;
  }
  .ml-lg-8_5,
  .mx-lg-8_5 {
    margin-left: 6.5rem !important;
  }
  .m-lg-9 {
    margin: 7rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 7rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 7rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 7rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 7rem !important;
  }
  .m-lg-9_5 {
    margin: 7.5rem !important;
  }
  .mt-lg-9_5,
  .my-lg-9_5 {
    margin-top: 7.5rem !important;
  }
  .mr-lg-9_5,
  .mx-lg-9_5 {
    margin-right: 7.5rem !important;
  }
  .mb-lg-9_5,
  .my-lg-9_5 {
    margin-bottom: 7.5rem !important;
  }
  .ml-lg-9_5,
  .mx-lg-9_5 {
    margin-left: 7.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-1_5 {
    padding: 0.5rem !important;
  }
  .pt-lg-1_5,
  .py-lg-1_5 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-1_5,
  .px-lg-1_5 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-1_5,
  .py-lg-1_5 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-1_5,
  .px-lg-1_5 {
    padding-left: 0.5rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-2_5 {
    padding: 0.8rem !important;
  }
  .pt-lg-2_5,
  .py-lg-2_5 {
    padding-top: 0.8rem !important;
  }
  .pr-lg-2_5,
  .px-lg-2_5 {
    padding-right: 0.8rem !important;
  }
  .pb-lg-2_5,
  .py-lg-2_5 {
    padding-bottom: 0.8rem !important;
  }
  .pl-lg-2_5,
  .px-lg-2_5 {
    padding-left: 0.8rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-4_5 {
    padding: 2rem !important;
  }
  .pt-lg-4_5,
  .py-lg-4_5 {
    padding-top: 2rem !important;
  }
  .pr-lg-4_5,
  .px-lg-4_5 {
    padding-right: 2rem !important;
  }
  .pb-lg-4_5,
  .py-lg-4_5 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-4_5,
  .px-lg-4_5 {
    padding-left: 2rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-5_5 {
    padding: 3.5rem !important;
  }
  .pt-lg-5_5,
  .py-lg-5_5 {
    padding-top: 3.5rem !important;
  }
  .pr-lg-5_5,
  .px-lg-5_5 {
    padding-right: 3.5rem !important;
  }
  .pb-lg-5_5,
  .py-lg-5_5 {
    padding-bottom: 3.5rem !important;
  }
  .pl-lg-5_5,
  .px-lg-5_5 {
    padding-left: 3.5rem !important;
  }
  .p-lg-6 {
    padding: 4rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4rem !important;
  }
  .p-lg-6_5 {
    padding: 4.5rem !important;
  }
  .pt-lg-6_5,
  .py-lg-6_5 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-6_5,
  .px-lg-6_5 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-6_5,
  .py-lg-6_5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-6_5,
  .px-lg-6_5 {
    padding-left: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 5rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 5rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 5rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 5rem !important;
  }
  .p-lg-7_5 {
    padding: 5.5rem !important;
  }
  .pt-lg-7_5,
  .py-lg-7_5 {
    padding-top: 5.5rem !important;
  }
  .pr-lg-7_5,
  .px-lg-7_5 {
    padding-right: 5.5rem !important;
  }
  .pb-lg-7_5,
  .py-lg-7_5 {
    padding-bottom: 5.5rem !important;
  }
  .pl-lg-7_5,
  .px-lg-7_5 {
    padding-left: 5.5rem !important;
  }
  .p-lg-8 {
    padding: 6rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 6rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 6rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 6rem !important;
  }
  .p-lg-8_5 {
    padding: 6.5rem !important;
  }
  .pt-lg-8_5,
  .py-lg-8_5 {
    padding-top: 6.5rem !important;
  }
  .pr-lg-8_5,
  .px-lg-8_5 {
    padding-right: 6.5rem !important;
  }
  .pb-lg-8_5,
  .py-lg-8_5 {
    padding-bottom: 6.5rem !important;
  }
  .pl-lg-8_5,
  .px-lg-8_5 {
    padding-left: 6.5rem !important;
  }
  .p-lg-9 {
    padding: 7rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 7rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 7rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 7rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 7rem !important;
  }
  .p-lg-9_5 {
    padding: 7.5rem !important;
  }
  .pt-lg-9_5,
  .py-lg-9_5 {
    padding-top: 7.5rem !important;
  }
  .pr-lg-9_5,
  .px-lg-9_5 {
    padding-right: 7.5rem !important;
  }
  .pb-lg-9_5,
  .py-lg-9_5 {
    padding-bottom: 7.5rem !important;
  }
  .pl-lg-9_5,
  .px-lg-9_5 {
    padding-left: 7.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-1_5 {
    margin: 0.5rem !important;
  }
  .mt-xl-1_5,
  .my-xl-1_5 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-1_5,
  .mx-xl-1_5 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-1_5,
  .my-xl-1_5 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-1_5,
  .mx-xl-1_5 {
    margin-left: 0.5rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-2_5 {
    margin: 0.8rem !important;
  }
  .mt-xl-2_5,
  .my-xl-2_5 {
    margin-top: 0.8rem !important;
  }
  .mr-xl-2_5,
  .mx-xl-2_5 {
    margin-right: 0.8rem !important;
  }
  .mb-xl-2_5,
  .my-xl-2_5 {
    margin-bottom: 0.8rem !important;
  }
  .ml-xl-2_5,
  .mx-xl-2_5 {
    margin-left: 0.8rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-4_5 {
    margin: 2rem !important;
  }
  .mt-xl-4_5,
  .my-xl-4_5 {
    margin-top: 2rem !important;
  }
  .mr-xl-4_5,
  .mx-xl-4_5 {
    margin-right: 2rem !important;
  }
  .mb-xl-4_5,
  .my-xl-4_5 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-4_5,
  .mx-xl-4_5 {
    margin-left: 2rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-5_5 {
    margin: 3.5rem !important;
  }
  .mt-xl-5_5,
  .my-xl-5_5 {
    margin-top: 3.5rem !important;
  }
  .mr-xl-5_5,
  .mx-xl-5_5 {
    margin-right: 3.5rem !important;
  }
  .mb-xl-5_5,
  .my-xl-5_5 {
    margin-bottom: 3.5rem !important;
  }
  .ml-xl-5_5,
  .mx-xl-5_5 {
    margin-left: 3.5rem !important;
  }
  .m-xl-6 {
    margin: 4rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4rem !important;
  }
  .m-xl-6_5 {
    margin: 4.5rem !important;
  }
  .mt-xl-6_5,
  .my-xl-6_5 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-6_5,
  .mx-xl-6_5 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-6_5,
  .my-xl-6_5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-6_5,
  .mx-xl-6_5 {
    margin-left: 4.5rem !important;
  }
  .m-xl-7 {
    margin: 5rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 5rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 5rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 5rem !important;
  }
  .m-xl-7_5 {
    margin: 5.5rem !important;
  }
  .mt-xl-7_5,
  .my-xl-7_5 {
    margin-top: 5.5rem !important;
  }
  .mr-xl-7_5,
  .mx-xl-7_5 {
    margin-right: 5.5rem !important;
  }
  .mb-xl-7_5,
  .my-xl-7_5 {
    margin-bottom: 5.5rem !important;
  }
  .ml-xl-7_5,
  .mx-xl-7_5 {
    margin-left: 5.5rem !important;
  }
  .m-xl-8 {
    margin: 6rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 6rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 6rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 6rem !important;
  }
  .m-xl-8_5 {
    margin: 6.5rem !important;
  }
  .mt-xl-8_5,
  .my-xl-8_5 {
    margin-top: 6.5rem !important;
  }
  .mr-xl-8_5,
  .mx-xl-8_5 {
    margin-right: 6.5rem !important;
  }
  .mb-xl-8_5,
  .my-xl-8_5 {
    margin-bottom: 6.5rem !important;
  }
  .ml-xl-8_5,
  .mx-xl-8_5 {
    margin-left: 6.5rem !important;
  }
  .m-xl-9 {
    margin: 7rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 7rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 7rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 7rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 7rem !important;
  }
  .m-xl-9_5 {
    margin: 7.5rem !important;
  }
  .mt-xl-9_5,
  .my-xl-9_5 {
    margin-top: 7.5rem !important;
  }
  .mr-xl-9_5,
  .mx-xl-9_5 {
    margin-right: 7.5rem !important;
  }
  .mb-xl-9_5,
  .my-xl-9_5 {
    margin-bottom: 7.5rem !important;
  }
  .ml-xl-9_5,
  .mx-xl-9_5 {
    margin-left: 7.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-1_5 {
    padding: 0.5rem !important;
  }
  .pt-xl-1_5,
  .py-xl-1_5 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-1_5,
  .px-xl-1_5 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-1_5,
  .py-xl-1_5 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-1_5,
  .px-xl-1_5 {
    padding-left: 0.5rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-2_5 {
    padding: 0.8rem !important;
  }
  .pt-xl-2_5,
  .py-xl-2_5 {
    padding-top: 0.8rem !important;
  }
  .pr-xl-2_5,
  .px-xl-2_5 {
    padding-right: 0.8rem !important;
  }
  .pb-xl-2_5,
  .py-xl-2_5 {
    padding-bottom: 0.8rem !important;
  }
  .pl-xl-2_5,
  .px-xl-2_5 {
    padding-left: 0.8rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-4_5 {
    padding: 2rem !important;
  }
  .pt-xl-4_5,
  .py-xl-4_5 {
    padding-top: 2rem !important;
  }
  .pr-xl-4_5,
  .px-xl-4_5 {
    padding-right: 2rem !important;
  }
  .pb-xl-4_5,
  .py-xl-4_5 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-4_5,
  .px-xl-4_5 {
    padding-left: 2rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-5_5 {
    padding: 3.5rem !important;
  }
  .pt-xl-5_5,
  .py-xl-5_5 {
    padding-top: 3.5rem !important;
  }
  .pr-xl-5_5,
  .px-xl-5_5 {
    padding-right: 3.5rem !important;
  }
  .pb-xl-5_5,
  .py-xl-5_5 {
    padding-bottom: 3.5rem !important;
  }
  .pl-xl-5_5,
  .px-xl-5_5 {
    padding-left: 3.5rem !important;
  }
  .p-xl-6 {
    padding: 4rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4rem !important;
  }
  .p-xl-6_5 {
    padding: 4.5rem !important;
  }
  .pt-xl-6_5,
  .py-xl-6_5 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-6_5,
  .px-xl-6_5 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-6_5,
  .py-xl-6_5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-6_5,
  .px-xl-6_5 {
    padding-left: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 5rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 5rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 5rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 5rem !important;
  }
  .p-xl-7_5 {
    padding: 5.5rem !important;
  }
  .pt-xl-7_5,
  .py-xl-7_5 {
    padding-top: 5.5rem !important;
  }
  .pr-xl-7_5,
  .px-xl-7_5 {
    padding-right: 5.5rem !important;
  }
  .pb-xl-7_5,
  .py-xl-7_5 {
    padding-bottom: 5.5rem !important;
  }
  .pl-xl-7_5,
  .px-xl-7_5 {
    padding-left: 5.5rem !important;
  }
  .p-xl-8 {
    padding: 6rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 6rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 6rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 6rem !important;
  }
  .p-xl-8_5 {
    padding: 6.5rem !important;
  }
  .pt-xl-8_5,
  .py-xl-8_5 {
    padding-top: 6.5rem !important;
  }
  .pr-xl-8_5,
  .px-xl-8_5 {
    padding-right: 6.5rem !important;
  }
  .pb-xl-8_5,
  .py-xl-8_5 {
    padding-bottom: 6.5rem !important;
  }
  .pl-xl-8_5,
  .px-xl-8_5 {
    padding-left: 6.5rem !important;
  }
  .p-xl-9 {
    padding: 7rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 7rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 7rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 7rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 7rem !important;
  }
  .p-xl-9_5 {
    padding: 7.5rem !important;
  }
  .pt-xl-9_5,
  .py-xl-9_5 {
    padding-top: 7.5rem !important;
  }
  .pr-xl-9_5,
  .px-xl-9_5 {
    padding-right: 7.5rem !important;
  }
  .pb-xl-9_5,
  .py-xl-9_5 {
    padding-bottom: 7.5rem !important;
  }
  .pl-xl-9_5,
  .px-xl-9_5 {
    padding-left: 7.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

/* .text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important;
} */

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}
/*# sourceMappingURL=bootstrap.css.map */

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important
}

.text-justify {
  text-align: justify !important
}

.text-wrap {
  white-space: normal !important
}

.text-nowrap {
  white-space: nowrap !important
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.text-left {
  text-align: left !important
}

.text-right {
  text-align: right !important
}

.text-center {
  text-align: center !important
}

@media (min-width:576px) {
  .text-sm-left {
      text-align: left !important
  }

  .text-sm-right {
      text-align: right !important
  }

  .text-sm-center {
      text-align: center !important
  }
}

@media (min-width:768px) {
  .text-md-left {
      text-align: left !important
  }

  .text-md-right {
      text-align: right !important
  }

  .text-md-center {
      text-align: center !important
  }
}

@media (min-width:992px) {
  .text-lg-left {
      text-align: left !important
  }

  .text-lg-right {
      text-align: right !important
  }

  .text-lg-center {
      text-align: center !important
  }
}

@media (min-width:1200px) {
  .text-xl-left {
      text-align: left !important
  }

  .text-xl-right {
      text-align: right !important
  }

  .text-xl-center {
      text-align: center !important
  }
}

.text-lowercase {
  text-transform: lowercase !important
}

.text-uppercase {
  text-transform: uppercase !important
}

.text-capitalize {
  text-transform: capitalize !important
}

.font-weight-light {
  font-weight: 300 !important
}

.font-weight-lighter {
  font-weight: lighter !important
}

.font-weight-normal {
  font-weight: 400 !important
}

.font-weight-bold {
  font-weight: 700 !important
}

.font-weight-bolder {
  font-weight: bolder !important
}

.font-italic {
  font-style: italic !important
}

.text-white {
  color: #fff !important
}

/* .text-primary {
  color: #007bff !important
}

a.text-primary:focus,
a.text-primary:hover {
  color: #0056b3 !important
} */

.text-secondary {
  color: #6c757d !important
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #494f54 !important
}

.text-success {
  color: #28a745 !important
}

a.text-success:focus,
a.text-success:hover {
  color: #19692c !important
}

.text-info {
  color: #17a2b8 !important
}

a.text-info:focus,
a.text-info:hover {
  color: #0f6674 !important
}

.text-warning {
  color: #ffc107 !important
}

a.text-warning:focus,
a.text-warning:hover {
  color: #ba8b00 !important
}

.text-danger {
  color: #dc3545 !important
}

a.text-danger:focus,
a.text-danger:hover {
  color: #a71d2a !important
}

.text-light {
  color: #f8f9fa !important
}

a.text-light:focus,
a.text-light:hover {
  color: #cbd3da !important
}

.text-dark {
  color: #343a40 !important
}

a.text-dark:focus,
a.text-dark:hover {
  color: #121416 !important
}

.text-body {
  color: #212529 !important
}

.text-muted {
  color: #6c757d !important
}

.text-black-50 {
  color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
  color: rgba(255, 255, 255, .5) !important
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0
}

.text-decoration-none {
  text-decoration: none !important
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important
}

.text-reset {
  color: inherit !important
}

.visible {
  visibility: visible !important
}

.invisible {
  visibility: hidden !important
}