body {
    background-color: lightgray;
}

cite {
    font-style: normal;
}

.bold {
    font-weight: bold;
}

.has-small-font-size {
    font-size: 0.85rem;
}